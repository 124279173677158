import { useState, useEffect } from 'react';
import WhiteListProofs from "proofs/wl_proofs.json"
import GiveawayProofs from "proofs/g_proofs.json"
import { ethers } from 'ethers';

export default function useProofs(address) {
    const [wl_1_proofs, set_wl_1_proofs] = useState(undefined);
    const [wl_2_proofs, set_wl_2_proofs] = useState(undefined);
    const [wl_3_proofs, set_wl_3_proofs] = useState(undefined);
    const [g_proofs, set_g_proofs] = useState(undefined);

    useEffect(() => {  
        if (address) {
            let _wl_1 = (WhiteListProofs.proofs[address.toLowerCase()] && WhiteListProofs.proofs[address.toLowerCase()]["1"]) || []
            let _wl_2 = (WhiteListProofs.proofs[address.toLowerCase()] && WhiteListProofs.proofs[address.toLowerCase()]["2"]) || []
            let _wl_3 = (WhiteListProofs.proofs[address.toLowerCase()] && WhiteListProofs.proofs[address.toLowerCase()]["3"]) || []
            let _g = (GiveawayProofs.proofs[address.toLowerCase()]) || []

            set_wl_1_proofs(_wl_1);
            set_wl_2_proofs(_wl_2);
            set_wl_3_proofs(_wl_3);
            set_g_proofs(_g);
        }
    }, [address])

    return [
        wl_1_proofs,
        wl_2_proofs,
        wl_3_proofs,
        g_proofs        
    ]
}