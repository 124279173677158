/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";

import Connect from "components/connect";
import StayUpdated from "components/stayUpdated";
import NoTickets from "components/noTickets";
import MintSection from "components/mintSection";
import MySloties from "components/mySloties";

import useWeb3 from "hooks/useWeb3";
import useProofs from "hooks/useProofs";

const MintPage = () => {
    //const navigate = useNavigate();
    const [usersTickets, setUsersTickets] = useState(undefined);

    const [userMintable, setUserMintable] = useState(undefined);

    const [balance, setBalance] = useState(undefined);

    const [giveAwayMinted, setGiveAwayMinted] = useState(undefined);

    const [isTx, setIsTx] = useState(undefined);

    // Mint section variables
    const [loading, setLoading] = useState(undefined);
    const loadingRef = useRef(loading);
    const isTxRef = useRef(isTx);

    const setLoadingRef = value => {
        loadingRef.current = value;
        setLoading(_ => value);
    }

    const setIsTxRef = value => {
        isTxRef.current = value;
        setIsTx(_ => value);
    }

    function contractDataLoaded() {
        let _allLoaded =
                        balance !== undefined &&
                        giveAwayMinted !== undefined && 
                        usersTickets !== undefined &&
                        userMintable !== undefined
        return _allLoaded
    }

    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const  [
        wl_1_proofs,
        wl_2_proofs,
        wl_3_proofs,
        g_proofs    
    ] = useProofs(address);

    useEffect(() => {
        const init = async () => {
            let _usersTickets = (await contractSales.addressToTicketsOpen(address))
                .add(await contractSales.addressToTicketsPermissioned(address, 1))
                .add(await contractSales.addressToTicketsPermissioned(address, 2))
                .add(await contractSales.addressToTicketsPermissioned(address, 3)).toNumber();
            let _userMinted = (await contractSales.addressToMints(address)).toNumber();
            let _userMintable = _usersTickets - _userMinted;                        
    
            let _balance = (await contractNFT.balanceOf(address)).toNumber();
    
            let _giveAwayMinted = (await contractSales.addressToGiveawayRedeemed(address)).toNumber();        
    
            setBalance(_balance);
            setGiveAwayMinted(_giveAwayMinted);
            setUsersTickets(_usersTickets);
            setUserMintable(_userMintable);

            provider.off("block");
            provider.on("block", (blockNumber) => {
                const update = async () => {
                    let _usersTickets = (await contractSales.addressToTicketsOpen(address))
                .add(await contractSales.addressToTicketsPermissioned(address, 1))
                .add(await contractSales.addressToTicketsPermissioned(address, 2))
                .add(await contractSales.addressToTicketsPermissioned(address, 3)).toNumber();
                    let _userMinted = (await contractSales.addressToMints(address)).toNumber();   
                    let _userMintable = _usersTickets - _userMinted;                 
            
                    let _balance = (await contractNFT.balanceOf(address)).toNumber();
                    let _giveAwayMinted = (await contractSales.addressToGiveawayRedeemed(address)).toNumber();        
            
            
                    setBalance(_ => _balance);
                    setGiveAwayMinted(_ => _giveAwayMinted);
                    setUsersTickets(_ => _usersTickets);
                    setUserMintable(_ => _userMintable);                    

                    if (!isTxRef.current && loadingRef.current) {
                        setLoadingRef(false);
                    }
                }                
                update();
            })
        }

        if (contractSales && provider && address && contractNFT && isCorrectChain) {
            init();
        }

    }, [contractSales, contractNFT, provider, address, isCorrectChain])

    function hasUserTickets() {
        return usersTickets > 0 || g_proofs.length > 0;
    }

    function hasMintedAll() {
        let _hasMintedAll = userMintable === 0;

        if (g_proofs.length > 0) {
            _hasMintedAll &= giveAwayMinted > 0
        }
        
        return _hasMintedAll;
    }

    return (
        <>
        {
            triedAutoConnecting &&
            (
                isConnected && isCorrectChain ? 
                    contractDataLoaded() ? (
                        hasUserTickets() || (balance > 0) ?
                                <>
                                    {
                                        !hasMintedAll() ?
                                            <MintSection 
                                                giveAwayMinted={giveAwayMinted}
                                                userTickets={usersTickets}
                                                userMintable={userMintable}
                                                balance={balance}
                                                contractDataLoaded={contractDataLoaded}
                                                g_proofs={g_proofs}
                                                contractSales={contractSales}
                                                signer={signer}
                                                address={address}
                                                loading={loading}
                                                setLoadingRef={setLoadingRef}
                                                setIsTxRef={setIsTxRef}
                                            />
                                        :
                                            <StayUpdated
                                                text={"You don't have any tickets left to mint."}
                                            />
                                    }
                                    {
                                        balance > 0 &&
                                            <MySloties 
                                                contract={contractNFT}
                                                balance={balance}
                                                address={address}
                                            />
                                    }                                
                                </>                            
                        :
                            <NoTickets />
                    ) : <></>
                :
                isConnected && !isCorrectChain ?
                    <StayUpdated 
                        text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                    />
                :
                <Connect connect={connect} />
            )
        }
        </>
    );
};

export default MintPage;
