import React, { useState, useEffect } from "react";

import Config from "config";

const ETHERSCAN_URL = Config.URLS.ETHERSCAN;

const MintSection = ({
    giveAwayMinted,
    userTickets,
    userMintable,
    balance,
    contractDataLoaded,
    g_proofs,
    contractSales,
    isCreated,
    signer,
    address,
    loading,
    setLoadingRef,
    setIsTxRef
}) => {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        //document.dispatchEvent(new Event('readystatechange'))
      })

    const [errorMsg, SetErrorMsg] = useState(undefined);
    const [txHash, setTxHash] = useState(undefined);

    function isGiveAwayEligible() {
        return g_proofs.length > 0 && giveAwayMinted === 0;
    }

    function hasTickets() {
        return userTickets > 0;
    }

    function hasMintsLeft() {
        return userMintable > 0;
    }

    function setCorrectErrorMessage(e) {
        SetErrorMsg("An unexpected error occured. Please try again.");
        for (let error of Config.ERRORS.MINT) {
            if (e.message.includes(error.error)) {
                SetErrorMsg(error.message)
                return;
            }
        }
    }

    const mint = async () => {
        SetErrorMsg(undefined)
        try {
            setLoadingRef(true);
            setIsTxRef(true)
            const tx = await contractSales.connect(signer).redeemTickets();

            setTxHash(tx.hash);
            await tx.wait();
        } catch (e) {
            setCorrectErrorMessage(e);
            setLoadingRef(false)
        }
        setTxHash(undefined);
        setIsTxRef(false);
    }

    const claimGiveaway = async () => {
        SetErrorMsg(undefined)
        try {
            if (isGiveAwayEligible()) {
                setLoadingRef(true);
                setIsTxRef(true)
                const tx = await contractSales.connect(signer).redeemGiveAway(
                    g_proofs[0],
                    g_proofs[1]
                );

                setTxHash(tx.hash);
                await tx.wait(2);
            }
        } catch (e) {
            setCorrectErrorMessage(e);
            setLoadingRef(false);
        }
        setTxHash(undefined)
        setIsTxRef(false);
    }

    const ViewResult = ({ phase, onClick }) => (
        <a onClick={(e) => {
            e.preventDefault();
            onClick();
        }} id="w-node-_6cc389b8-57cd-fd02-3bea-7cadab2fe922-b62521ab" href="#" className="button large w-button">{phase}</a>
    )

    const ResultSection = ({ phase, bought }) => (
        <>
            <div className="wrapper flex align-baseline wrap justify-centre">
                <div className="text h5">Tickets purchased: {bought}</div>
            </div>
        </>
    )

    const MintSection = ({phase, mintable, mint}) => (
        <>
            <a onClick={(e) => {
                e.preventDefault();
                mint();
            }} id="w-node-_4013a355-e959-c384-dd5a-1d04264fa9bc-b62521ab" href="#" className="button large w-button">Mint {mintable} Slotie Juniors</a>
        </>
    )

    const LoadingSection = () => (
        <>
            <div id="w-node-_3f5172fc-3a99-4198-b0a6-daa31cbe7241-b62521ab" className="text centred" style={{color: "#58d658"}}>Transaction is loading...</div>
            {
                txHash &&
                <>
                    <a id="w-node-_25001b7d-fd59-7a5d-be46-9e81beb750ae-b62521ab" href={ETHERSCAN_URL + "/tx/" + txHash} target="_blank" className="link-block w-inline-block">
                        <div className="text bold centred">View transaction on Etherscan.</div>
                    </a>
                    <div data-w-id="801ce689-37a9-6e14-c8b5-ed4298891929" data-animation-type="lottie" data-src="documents/lottieflow-loading-04-1-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="3.1458333333333335" data-duration="0" id="w-node-_801ce689-37a9-6e14-c8b5-ed4298891929-b62521ab" className="lottie buy-ticket-loading-lottie"></div>
                </>
            }
        </>
    )

    return (
        <div className="wrapper redeem-ticket-wrapper">
            <div className="grid redeem-tickets-grid">
                <div>
                    <div className="text h2">Mint your Slotie Juniors.</div>
                    <div className="horizontal-line"></div>
                    <div className="spacer _1em"></div>
                </div>
                <div style={{ textAlign: 'left' }} className="text">
                    <p>You can turn all of your purchased tickets into Slotie Junior NFTs here. If you won a Slotie Junior NFT in our Discord giveaways you can mint that as well.</p>
                    <p>After minting your Juniors they will not be revealed for a week, or until 100% is sold out. We added this feature to protect our community from snipers.</p>
                    <p>The reveal will happen simultaneously on our WEB & Rarible.</p>
                    <p>Your tickets will expire in 1 week - take your time to mint whenever the <a href="https://etherscan.io/gastracker" target="_blank">gas fees</a> are the lowest. We advise to mint on weekends.</p>
                </div>
                {
                    hasTickets() &&
                    hasMintsLeft() &&
                    !loading &&
                    <div id="w-node-b47e6c7f-ff46-c815-b2b9-76b4aefbdcc6-b62521ab" className="text bold">Your results:</div>
                }
                {
                    hasTickets() &&
                    hasMintsLeft() &&
                    !loading &&
                        <ResultSection
                            phase={""}
                            bought={userTickets}
                        />
                }

                {
                    hasMintsLeft() &&
                    !loading &&

                    <div id="w-node-b47e6c7f-ff46-c815-b2b9-76b4aefbdcc6-b62521ab" className="text bold">Mint</div>
                }

                {
                    hasMintsLeft() &&
                    !loading &&
                        <MintSection
                            phase={""}
                            mintable={userMintable}
                            mint={mint}
                        />
                }

                {
                    isGiveAwayEligible()  &&
                    !loading &&
                        <ViewResult
                            phase={`Mint ${g_proofs[0]} Slotie Juniors from Giveaway`}
                            onClick={claimGiveaway}
                        />
                }

                {
                    loading &&
                        <LoadingSection />
                }

                {
                    errorMsg &&
                    <div className="error-message text centred" style={{color: "red"}}>
                        {errorMsg}
                    </div>
                }

            </div>
        </div>
    )
}

export default MintSection
