/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Connect from "components/connect";
import PhaseThreeSection from "components/phaseThreeSection/";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";
import useProofs from "hooks/useProofs";

import Config from "config";

const PhaseThree= () => {
    const [isActive, setIsActive] = useState();
    const [startTime, setStartTime] = useState();
    const [isFinished, setIsFinished] = useState();

    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    useEffect(() => {
        if (contractSales && isCorrectChain) {
            (async function (){
                try {
                    let _blockNum = await provider.getBlockNumber();
                    let _block = await provider.getBlock(_blockNum);
                    
                    let _start = (await contractSales.startTimeOpen()).toNumber()
                    let _active = _block.timestamp >= _start;
                    let _ticketsSold = (await contractSales.boughtOpen()).toNumber();
                    
                    setIsActive(_active)
                    setStartTime(_start)
                    setIsFinished(_ticketsSold > 1 && !_active)
                } catch (e) {
                    console.log(e)
                }

            })();
        }
    }, [contractSales, isCorrectChain]);

    async function checkIsStarted () {
        let _blockNum = await provider.getBlockNumber();
        let _block = await provider.getBlock(_blockNum);
        let _active = _block.timestamp >= startTime;
        
        setIsActive(_active)
    }

    function isStartedButNotActive () {
        return startTime && Math.floor(Date.now() / 1000) >= startTime && !isActive
    }
    
    useEffect(() => {
        if (provider && startTime) {
            provider.on("block", _ => {
                console.log("block update")
                checkIsStarted();
            })
            return () => provider.off("block")
        }
    }, [provider, startTime])

    const  [
        wl_1_proofs,
        wl_2_proofs,
        wl_3_proofs,
        g_proofs        
    ] = useProofs(address);

    return (
        <>
        {
            triedAutoConnecting &&
            (
                isConnected && isCorrectChain ?
                    isActive !== undefined && (
                        isActive ?
                            <PhaseThreeSection
                                address={address}
                                contract={contractSales}
                                signer={signer}
                                provider={provider}
                                wl_3_proofs={wl_3_proofs}
                            />
                            :
                            isFinished ?
                            <StayUpdated
                                text={"Public sale is finished."}
                            />
                            :
                            <StayUpdated
                                text={"Public sale is not active yet."}
                                link={isStartedButNotActive() ? "#" : undefined}
                                linkText={isStartedButNotActive() ? "Sale starting any moment now. Please keep refreshing the screen." : undefined}
                            />
                    )
                :
                isConnected && !isCorrectChain ?
                    <StayUpdated
                        text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                    />
                :
                <Connect connect={connect} />
            )
        }
        </>
    );
};

export default PhaseThree;
