import React, { Suspense, useEffect } from 'react';

import { HashRouter, Route, Routes } from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";

import PhaseOne from "./pages/phaseOne"
import PhaseTwo from "./pages/phaseTwo"
import PhaseThree from "./pages/phaseThree"
import MintPage from "./pages/mint"
import StayUpdated from "./components/stayUpdated"

const App = () => {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    })

    return (
        <React.StrictMode>
            <Suspense
                fallback={
                    <div className="loadingPage">
                    </div>
                }
            >

                <Header />
                    <section id="what-are-sloties" className="section mint-section">
                        <div className="container minting-container">
                        {
                            <HashRouter>
                                <Routes>
                                    <Route
                                        path="/mint"
                                        element={<MintPage/>}
                                    />
                                    <Route
                                        path="/public"
                                        element={<PhaseThree/>}
                                    />
                                    <Route
                                        path="/presale-1"
                                        element={<PhaseOne/>}
                                    />
                                    <Route
                                        path="/presale-2"
                                        element={<PhaseTwo/>}
                                    />
                                    <Route
                                        path="/"
                                        element={<StayUpdated
                                            text={"Welcome to the Slotie Junior NFT Official Sale. Minting will start at February 10th, 7:00 PM UTC."}
                                        />}
                                    />
                                </Routes>
                            </HashRouter>
                        }
                        </div>
                    </section>
                <Footer />

            </Suspense>
        </React.StrictMode>
    )
}

export default App