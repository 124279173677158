/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {ethers} from "ethers";
import axios from "axios";

import Connect from "components/connect";
import PhaseTwoSection from "components/phaseTwoSection";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";
import useProofs from "hooks/useProofs";

import Config from "config";
const PUBLIC_SALE_URL = Config.URLS.BASE + "/public";

const PhaseTwo = () => {
    const [isActive, setIsActive] = useState(undefined);
    const [startTime, setStartTime] = useState();
    //const [isFinished, setIsFinished] = useState(undefined);
    
    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const now = () => Math.floor(Date.now() / 1000)

    const  [
        wl_1_proofs,
        wl_2_proofs,
        wl_3_proofs,
        g_proofs        
    ] = useProofs(address);

    useEffect(() => {
        if (contractSales && isCorrectChain) {
            (async function (){
                try {
                    let _blockNum = await provider.getBlockNumber();
                    let _block = await provider.getBlock(_blockNum);
                    
                    let _start = (await contractSales.phaseTwoStartTime()).toNumber()
                    let _active = _block.timestamp >= _start;

                    setIsActive(_active)
                    setStartTime(_start)
                } catch (e) {
                    console.log(e)
                }

            })();
        }
    }, [contractSales, isCorrectChain]);

    async function checkIsStarted () {
        let _blockNum = await provider.getBlockNumber();
        let _block = await provider.getBlock(_blockNum);
        let _active = _block.timestamp >= startTime;
        
        setIsActive(_active)
    }

    useEffect(() => {
        if (provider && startTime) {
            provider.on("block", _ => {
                console.log("block update")
                checkIsStarted();
            })
            return () => provider.off("block")
        }
    }, [provider, startTime])

    function isStartedButNotActive () {
        return startTime && Math.floor(Date.now() / 1000) >= startTime && !isActive
    }


    function isWhitelisted() {
        return wl_2_proofs && wl_2_proofs.length > 0;
    }

    return (
        <>
        {
            triedAutoConnecting &&
                (
                    isConnected && isCorrectChain ? 
                        isActive !== undefined && 
                        (isWhitelisted() ?
                            isActive ?
                                <PhaseTwoSection 
                                    address={address}
                                    wl_1_proofs={wl_1_proofs}
                                    wl_2_proofs={wl_2_proofs}
                                    contract={contractSales}
                                    signer={signer}
                                    provider={provider}
                                />
                               // :
                               // isFinished ?
                               // <StayUpdated 
                               //     text={"Pre-sale two is finished."}
                               // />
                                :                     
                                <StayUpdated 
                                    text={"Pre-sale two is not active yet."}
                                    link={isStartedButNotActive() ? "#" : undefined}
                                    linkText={isStartedButNotActive() ? "Sale starting any moment now. Please keep refreshing the screen." : undefined}
                                />
                        :
                        <StayUpdated 
                            text={"You are not whitelisted! Please visit the public sale page for more info."}
                            link={PUBLIC_SALE_URL}
                            linkText={"Public Sale"}
                        />)
                    :
                    isConnected && !isCorrectChain ?
                        <StayUpdated 
                            text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                        />
                    :
                    <Connect connect={connect} />
                )
        }
        </>
    );
};

export default PhaseTwo;

