import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useSlotieJrNFTs(contract, balance, address) {
    const [tokens, setTokens] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [imageData, setImageData] = useState([]);

    useEffect(() => {
        if (contract && address && balance > 0) {
            (async function(){
                let _tokens = [];
                let _metadatas = [];
                let _imagedatas = [];
                
                for (let i = 0; i < balance; i++) {
                    let tokenId = (await contract.tokenOfOwnerByIndex(address, i)).toNumber()
                    let metadataUrl = await contract.tokenURI(tokenId)
                    let metadata = await axios.get(metadataUrl)
                    metadata = metadata ? metadata.data: metadata
                    _tokens.push(tokenId);
                    _metadatas.push(metadata);
                    _imagedatas.push(metadata ? metadata.image : "")
                }

                setTokens(_tokens);
                setMetadata(_metadatas);
                setImageData(_imagedatas);            
            })()
        }
    }, [contract, balance, address])

    return [
        tokens,
        metadata,
        imageData
    ]
}