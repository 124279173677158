/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Connect from "components/connect";
import PhaseOneSection from "components/phaseOneSection";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";
import useProofs from "hooks/useProofs";

import Config from "config";
const PUBLIC_SALE_URL = Config.URLS.BASE + "/public";
const PHASE_TWO_URL = Config.URLS.BASE + "/presale-2"


const PhaseOne = () => {
    const [isActive, setIsActive] = useState(undefined);
    const [startTime, setStartTime] = useState();
    //const [isFinished, setIsFinished] = useState(undefined);

    /** WEB 3 */
    const [
        provider,
        signer,
        address,
        contractNFT,
        contractSales,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    useEffect(() => {
        (async function(){
            await tryAutoConnect();
            setTriedAutoConnecting(true);
        })()
    }, [])

    const  [
        wl_1_proofs,
        wl_2_proofs,
        wl_3_proofs,
        g_proofs        
    ] = useProofs(address);

    const now = () => Math.floor(Date.now() / 1000)

    useEffect(() => {
        if (contractSales && isCorrectChain) {
            (async function (){
                try {
                    let _blockNum = await provider.getBlockNumber();
                    let _block = await provider.getBlock(_blockNum);

                    let _start = (await contractSales.phaseOneStartTime()).toNumber()
                    let _active = _block.timestamp >= _start;
                    
                    setIsActive(_active)
                    setStartTime(_start)
                } catch (e) {
                    console.log(e)
                }

            })();
        }
    }, [contractSales, isCorrectChain]);

    async function checkIsStarted () {
        let _blockNum = await provider.getBlockNumber();
        let _block = await provider.getBlock(_blockNum);
        let _active = _block.timestamp >= startTime;
        
        setIsActive(_active)
    }

    useEffect(() => {
        if (provider && startTime) {
            provider.on("block", _ => {
                console.log("block update")
                checkIsStarted();
            })
            return () => provider.off("block")
        }
    }, [provider, startTime])

    function isStartedButNotActive () {
        return startTime && Math.floor(Date.now() / 1000) >= startTime && !isActive
    }

    function isWhitelisted() {
        return wl_1_proofs && wl_1_proofs.length > 0;
    }

    return (
        <>
        {
            triedAutoConnecting &&
            (
                isConnected && isCorrectChain ? 
                    isActive !== undefined && 
                    (isWhitelisted() ?
                        isActive ?
                            <PhaseOneSection 
                                address={address}
                                wl_1_proofs={wl_1_proofs}
                                contract={contractSales}
                                signer={signer}
                                provider={provider}
                            />
                            :
                            //isFinished ?
                            //<StayUpdated 
                            //    text={"Pre-sale one is finished."}
                           // />
                           // :                     
                            <StayUpdated 
                                text={"Pre-sale one is not active yet."}
                                link={isStartedButNotActive() ? "#" : undefined}
                                linkText={isStartedButNotActive() ? "Sale starting any moment now. Please keep refreshing the screen." : undefined}
                            />
                    :
                    wl_2_proofs && wl_2_proofs.length > 0 ?                    
                        <StayUpdated 
                            text={"You are not whitelisted! Please visit the second pre sale page for more info."}
                            link={PHASE_TWO_URL}
                            linkText={"Presale 2"}
                        />
                        :
                        <StayUpdated 
                            text={"You are not whitelisted! Please visit the public sale page for more info."}
                            link={PUBLIC_SALE_URL}
                            linkText={"Public Sale"}
                        />
                    )
                :
                isConnected && !isCorrectChain ?
                    <StayUpdated 
                        text={"You are not connected to the correct network. Please connect to the Ethereum main network."}
                    />
                :
                <Connect connect={connect} />
            )
        }
        </>
    );
};

export default PhaseOne;
