import React, { useState, useEffect } from "react";

const Footer = () => {
    return (
        <div className="section footer-section">
            <div className="wrapper bg-wrapper overflow">
            <div data-w-id="c1dbda6a-f9ff-7dbd-3c22-48f4aa97c684" data-animation-type="lottie" data-src="https://uploads-ssl.webflow.com/61891ae2510fa12a40697998/61891ae2510fa1e0336979d1_lottieflow-background-05-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="8" data-duration="0" className="lottie footer-bg-lottie"></div>
            </div>
            <div className="container footer-container">
            <div className="spacer _3em"></div>
            <div className="horizontal-line"></div>
            <div className="spacer _1em"></div>
            <div className="grid footer-footer-grid">
                <div className="text extra-small">© 2022 Slotie NFT. All rights reserved.</div>
                <a href="https://www.slotie.com/terms" target="_blank" className="link footer-link">Terms &amp; Conditions</a>
            </div>
            </div>
        </div>
    )
}

export default Footer;